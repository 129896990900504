<template>
  <div>
    <Navigation />

    <div class="py-2 px-5">
      <DashboardTitle class="mb-2 mt-1"></DashboardTitle>

      <v-row>
        <v-col cols="12" md="6">
          <BuscadorClientes
            :currentFilters="filtros"
            :loading="loading"
            @searchUpdated="getClientes"
          ></BuscadorClientes>
        </v-col>
        <v-col cols="12" md="6">
          <ClientesStatusGraphic
            :clientes="todosLosClientes"
            @searchUpdated="getClientes"
          ></ClientesStatusGraphic>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar dense flat dark color="primary">
              <v-toolbar-title>
                <span>Resultados de la búsqueda</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <TablaClientes
                :loading="loading"
                :clientes="clientes"
              ></TablaClientes>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import BuscadorClientes from "./BuscadorClientes.vue";
import TablaClientes from "./TablaClientes.vue";
import ClientesStatusGraphic from "./ClientesStatus.graphic.vue";

import Navigation from "../../../../components/Nav.vue";
import DashboardTitle from "./DashboardTitle.vue";

import { mainAxios } from "../../../../mainAxios";

export default {
  components: {
    Navigation,
    DashboardTitle,
    BuscadorClientes,
    TablaClientes,
    ClientesStatusGraphic,
  },

  data() {
    return {
      loading: false,
      requestConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },

      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      clientes: [],
      todosLosClientes: {},
      filtros: {},

      clienteStatus: [
        {
          id: 0,
          title: "Nuevo",
        },
        {
          id: 1,
          title: "Activo",
        },
        {
          id: 2,
          title: "Inactivo",
        },
        {
          id: -1,
          title: "Baja",
        },
      ],
    };
  },

  computed: {
    puedeCrear() {
      const rolesValidos = [
        "ADMIN",
        "ADMINCC",
        "MANAGERCC",
        "MANAGER",
        "ADMINAGENT",
        "DIRECTO",
        "OPERACIONES",
        "OPERADOR",
        "MESADECONTROL",
      ];
      return !!rolesValidos.find((e) => e == this.rol);
    },
  },

  mounted() {
    this.getTodosLosClientes();
  },

  methods: {
    getClientes(body) {
      this.loading = true;
      mainAxios
        .post(`/v1/cliente/list/filtros`, body, this.requestConfig)
        .then(({ data }) => {
          this.loading = false;
          if (data.length)
            this.clientes = data.map((cliente) => {
              const result = {
                ...cliente,
              };
              result.status = this.clienteStatus.find(
                (e) => e.id === result.status
              ).title;

              return result;
            });
          else this.clientes = [];
          this.filtros = body;
        });
    },

    getTodosLosClientes() {
      mainAxios
        .get(`v1/cliente/dashboard/data`, this.requestConfig)
        .then(({ data }) => {
          this.todosLosClientes = data;
        });
    },
  },
};
</script>
